// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { json, redirect } from '@remix-run/cloudflare';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import { parse } from 'cookie';
import { useRef } from 'react';
import { useFullscreen, useToggle } from 'react-use';
import { QueryClient, QueryClientProvider } from 'react-query';
import tailwind from '~/styles/tailwind.css';
import { elementNotContainedByClickTarget } from './utils/elementNotContainedByClickTarget';
import getUsername from './utils/getUsername.server';
import { cn } from './utils/style';
function addOneDay(date) {
  const result = new Date(date);
  result.setTime(result.getTime() + 24 * 60 * 60 * 1000);
  return result;
}
export const loader = async ({
  request,
  context
}) => {
  const url = new URL(request.url);
  const username = await getUsername(request);
  if (!username && url.pathname !== '/set-username') {
    const redirectUrl = new URL(url);
    redirectUrl.pathname = '/set-username';
    redirectUrl.searchParams.set('return-url', request.url);
    throw redirect(redirectUrl.toString());
  }
  const defaultResponse = json({
    userDirectoryUrl: context.env.USER_DIRECTORY_URL
  });

  // we only care about verifying token freshness if request was a user
  // initiated document request.
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Sec-Fetch-User
  const secFetchUser = request.headers.get('Sec-Fetch-User');
  if (secFetchUser !== '?1') return defaultResponse;
  const cookiesHeader = request.headers.get('Cookie');
  if (!cookiesHeader) return defaultResponse;
  const {
    CF_Authorization
  } = parse(cookiesHeader);
  if (!CF_Authorization) return defaultResponse;
  const [, payload] = CF_Authorization.split('.');
  const data = JSON.parse(atob(payload));
  const expires = new Date(data.exp * 1000);
  const now = new Date();
  if (addOneDay(now) > expires) {
    const headers = new Headers();
    ['CF_Authorization', 'CF_AppSession'].forEach(cookieName => headers.append('Set-Cookie', `${cookieName}=; Expires=${new Date(0).toUTCString()}; Path=/;`));
    throw redirect(request.url, {
      headers
    });
  }
  return defaultResponse;
};
export const meta = () => [{
  title: 'Temple Calls'
}];
export const links = () => [{
  rel: 'stylesheet',
  href: tailwind
}, {
  rel: 'apple-touch-icon',
  sizes: '180x180',
  href: '/apple-touch-icon.png?v=blue-emoji'
}, {
  rel: 'icon',
  type: 'image/png',
  sizes: '32x32',
  href: '/favicon-32x32.png?v=blue-emoji'
}, {
  rel: 'icon',
  type: 'image/png',
  sizes: '16x16',
  href: '/favicon-16x16.png?v=blue-emoji'
}, {
  rel: 'manifest',
  href: '/site.webmanifest',
  crossOrigin: 'use-credentials'
}, {
  rel: 'mask-icon',
  href: '/safari-pinned-tab.svg?v=blue-emoji',
  color: '#faa339'
}, {
  rel: 'shortcut icon',
  href: '/favicon.ico?v=blue'
}];
const Document = ({
  children
}) => {
  _s();
  const fullscreenRef = useRef(null);
  const [fullscreenEnabled, toggleFullscreen] = useToggle(false);
  useFullscreen(fullscreenRef, fullscreenEnabled, {
    onClose: () => toggleFullscreen(false)
  });
  return (
    // some extensions add data attributes to the html
    // element that React complains about.
    <html className="h-full" lang="en" suppressHydrationWarning>
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="apple-mobile-web-app-title" content="Temple Calls" />
				<meta name="application-name" content="Temple Calls" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: light)" />

				<meta name="theme-color" content="#232325" media="(prefers-color-scheme: dark)" />

				<Meta />
				<Links />
			</head>
			<body className={cn('h-full', 'bg-white', 'text-zinc-800', 'dark:bg-zinc-800', 'dark:text-zinc-200')} ref={fullscreenRef} onDoubleClick={e => {
        if (e.target instanceof HTMLElement && !elementNotContainedByClickTarget(e.target)) toggleFullscreen();
      }}>

				{children}
				<ScrollRestoration />
				<div className="hidden" suppressHydrationWarning>
					{/* Replaced in entry.server.ts */}
					__CLIENT_ENV__
				</div>
				<Scripts />
				<LiveReload />
			</body>
		</html>
  );
};
_s(Document, "EnJpkk45ATvxzMOYfjCE43rzFUc=", false, function () {
  return [useToggle, useFullscreen];
});
_c = Document;
export const ErrorBoundary = () => {
  return <Document>
			<div className="grid h-full place-items-center">
				<p>
					It looks like there was an error, but don't worry it has been
					reported. Sorry about that!
				</p>
			</div>
		</Document>;
};
_c2 = ErrorBoundary;
const queryClient = new QueryClient();
export default function App() {
  _s2();
  const {
    userDirectoryUrl
  } = useLoaderData();
  return <Document>
			<div id="root" className="h-full bg-inherit isolate">
				<QueryClientProvider client={queryClient}>
					<Outlet context={{
          userDirectoryUrl
        }} />

				</QueryClientProvider>
			</div>
		</Document>;
}
_s2(App, "ScEDMKWYUH35cEpdvC10ICUnZpg=", false, function () {
  return [useLoaderData];
});
_c3 = App;
var _c, _c2, _c3;
$RefreshReg$(_c, "Document");
$RefreshReg$(_c2, "ErrorBoundary");
$RefreshReg$(_c3, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;